import React from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import { StaticImage } from "./StaticImage";
import * as Icon from "./Icon";

const IMAGES = [{ imgLeft: "revtel-brand.png", imgRight: "revtel-office.png" }];

class OfficeCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemPerRow: 2,
      slideIndex: 0,
      slideHeight: 357.92,
    };
  }

  componentDidMount() {
    this._setCarouselItemRow = setInterval(() => {
      let { itemPerRow } = this.state;

      // set item per row
      if (window.screen.width > 500 && itemPerRow !== 2) {
        this.setState({ itemPerRow: 2 });
      } else if (window.screen.width <= 500 && itemPerRow !== 1) {
        this.setState({ itemPerRow: 1 });
      }
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this._setCarouselItemRow);
  }

  render() {
    let { itemPerRow, slideIndex, slideHeight } = this.state;
    return (
      <Wrapper>
        <Carousel
          initialSlideHeight={slideHeight}
          slideIndex={this.state.slideIndex}
          afterSlide={slideIndex => this.setState({ slideIndex })}
          renderCenterLeftControls={() => false}
          renderCenterRightControls={() => false}
          renderBottomCenterControls={() => false}
          style={{ outline: "none" }}
        >
          {itemPerRow === 2
            ? IMAGES.map((img, i) => (
                <ImageContainer>
                  <div className="img-item">
                    <StaticImage
                      path={"/about/"}
                      name={img.imgLeft}
                      style={{ borderRight: "1px solid white" }}
                    />
                  </div>
                  <div className="img-item">
                    <StaticImage name={img.imgRight} />
                  </div>
                </ImageContainer>
              ))
            : this._reconstructItems().map((img, i) => (
                <ImageContainer>
                  <div className="img-item">
                    <StaticImage name={img.name} />
                  </div>
                </ImageContainer>
              ))}
        </Carousel>
        {this._renderArrowIcon()}
      </Wrapper>
    );
  }

  _reconstructItems = () => {
    let result = [];
    IMAGES.forEach((e, idx, array) => {
      result.push({ name: e.imgLeft }, { name: e.imgRight });
    });
    return result;
  };

  _renderArrowIcon = () => {
    let { slideIndex } = this.state;

    return (
      <>
        <IconButton
          left
          onClick={() => this.setState({ slideIndex: slideIndex - 1 })}
        >
          <Icon.KeyboardArrowLeft color="white" size={26} />
        </IconButton>

        <IconButton
          onClick={() => this.setState({ slideIndex: slideIndex + 1 })}
        >
          <Icon.KeyboardArrowRight color="white" size={26} />
        </IconButton>
      </>
    );
  };
}

const Wrapper = styled.div`
  position: relative;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  max-height: 357.92px;
  height: 100%;

  & .img-item {
    width: 50%;
  }
  @media screen and (max-width: 1024px) {
    height: 199.88px;
    & .img-item {
    }
  }
  @media screen and (max-width: 900px) {
    & .img-item {
    }
  }
  @media screen and (max-width: 768px) {
    height: 234.41px;
    & .img-item {
    }
  }
  @media screen and (max-width: 600px) {
    height: 178.63px;
    & .img-item {
    }
  }
  @media screen and (max-width: 500px) {
    height: 332.03px;
    & .img-item {
      width: 100%;
    }
  }

  @media screen and (max-width: 414px) {
    height: 274.92px;
    & .img-item {
    }
  }

  @media screen and (max-width: 375px) {
    height: 249.02px;
    & .img-item {
      height: 300px;
    }
  }
  @media screen and (max-width: 320px) {
    height: 212.5px;
    & .img-item {
      height: 260px;
    }
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: linear-gradient(22deg, #faa34e 18%, #f36a26 91%);

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  ${props => (props.left ? "left: 0px" : "right: 0px")};
`;

export default OfficeCarousel;
